<template>
  <div class="legal">
    <div class="background"></div>
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="8">
          <p class="titleName text-h4 mb-10 text-center titleName">「麻雀一番街」の配信ガイドライン</p>
          <p>Lancelot Tech Ltd（以下、当社）は、「麻雀一番街」（以下、本コンテンツ）という著作物の利用に関するクリエイターの創作活動を支援・推奨します。これからも、当社は、すべてのクリエイターの活動に敬意と共感を持って文化の発展に貢献いたします。</p>
          <p>本コンテンツをより多くの方々に広め、親しんでもらえるようコンテンツの利用に関するガイドラインを制定しました。</p>
          <p>本コンテンツのゲーム配信及び動画投稿を利用する際には、以下の内容をお守りの上お楽しみください。</p>
          <p>1. お客様は本コンテンツから取り込んだゲームプレイの動画や静止画を、下記の場所にてご利用いただけます。</p>
          <p>（1）YouTube、Twitch、Twitter、Facebook、ゲームの攻略・紹介・実況・解説など動画投稿サイト</p>
          <p>（2）お客様の個人的、かつ非営利目的のブログ・ホームページ・及びＳＮＳ</p>
          <p>（3）当該掲載箇所が以下に該当する場合にはご利用いただけません。</p>
          <p style="text-indent: 1em;">① わいせつ・暴力その他公序良俗に反する場合</p>
          <p style="text-indent: 1em;">② 当社や本ゲームの名誉・信用を不当に損なう場合</p>
          <p style="text-indent: 1em;">③ 法令に違反する場合</p>
          <p style="text-indent: 1em;">④ その他当社が不適切と認める場合</p>
          <p>2. お客様は下記に記載した内容を除き、本コンテンツを使用して金銭的利益を得ることはできません。</p>
          <p>（1）個人が作成した動画に関しては、YouTube、Twitchのパートナープログラム、その他の動画共有サイトの広告を通じて収益化できます。</p>
          <p>（2）YouTube、Twitch、またはその他の動画共有サイトで動画が無料で公開されており、当該サイトや当社個別タイトルで当該行為を禁止していない限り、第三者からの自発的な寄付的行為により金銭的利益を得ることを許可します。</p>
          <p>（3）法人（芸能事務所・プロダクション等所属の個人、法人と契約して動画配信をしている個人を含みます）が動画を配信する場合はこの限りではありません。ビジネス目的での使用を希望される場合は当社へご連絡ください。</p>
          <p>3.プレイ動画の著作権は当社または正当な権利者に帰属しております。</p>
          <p>4.お客様が投稿したコンテンツを、当社の公式動画として宣伝することはできません。</p>
          <p>5.音楽を含む動画：ゲーム音楽を、ゲーム映像から切り離し、個別に投稿または配布することはできません。</p>
          <p>6.視聴者に適した内容：お客様が作成したすべての動画は、当社が想定するゲーム視聴者に適した内容である必要があります。たとえば、各ゲームのレーティングを無視した内容である場合、当社は当該動画を削除する可能性があります。</p>
          <p>7.本コンテンツ内で別途指定されている配信が禁止されている区域の投稿は対象外といたします。</p>
          <p>8.収益化をする場合、本コンテンツがコラボレーションした他作品のキャラクター・イラスト・楽曲・音楽データなどを含む投稿は対象外といたします。</p>
          <p>9.本ガイドラインは、日本国内のみを対象といたします。</p>
          <p>10.前項までに定めるもののほか、当社は、次の事項に該当する、もしくはおそれのある内容の投稿に対し、その投稿の削除を含む法的手段を講じる場合があります。</p>
          <p>（1）このガイドラインに従わないもの。</p>
          <p>（2）当社やその関係者による投稿と誤解を与えるもの。</p>
          <p>（3）事実に反し、当社やその関係者から協賛や提携を受けているかのような印象を与えるもの。</p>
          <p>（4）犯罪、反社会的活動その他の各国法令違反となるもの、もしくはそれを肯定、助長するもの。</p>
          <p>（5）本コンテンツの利用規約に反するもの、もしくはそれを肯定、助長するもの。</p>
          <p>（6）知的財産権を侵害するもの。</p>
          <p>（7）不正アクセスや、プログラムやデータを不正に取得、改変、改ざんするもの、もしくはそれを肯定、助長するもの。</p>
          <p>（8）不正に入手したアカウントによるもの。</p>
          <p>（9）関係者及び本コンテンツのイメージや評判を損なうもの。</p>
          <p>（10）当社が別途許諾した場合を除き、第三者の商品やサービスの宣伝となるもの。</p>
          <p>（11）公序良俗に反するもの。</p>
          <p>（12）その他、当社が不適切と判断したもの。</p>
          <p>11.当ガイドラインの内容は随時変更、更新されます。投稿前に最新のガイドラインをご確認ください。</p>
          <p>当ガイドラインに関するお問い合わせ先</p>
          <p>riichicitysupport@mahjong-jp.com</p>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.legal{
  position: relative; 
  .background{
    position: fixed;
    display: block;
    top:0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('/static/img/common/policyBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
  }
  .content_style{
    z-index: 3;
  }
  .titleName {
    font-size: 50px;
    color: #ffffff; 
    font-weight: 900;
    text-indent: 0em;
  }
  h2 {
    color: #ffffff; 
    font-weight: 700;
    margin: 60px 0 30px 0;
    font-size: 32px;
    text-indent: 0;
  }
  p {
    color: #ffffff; 
    text-indent: 0em;
    font-weight: 500;
  }
}
</style>